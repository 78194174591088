import React, { useState, useRef, useEffect } from 'react';
import {
  Col, Row, Button, Form, FormGroup, Label, Input, Alert,
} from 'reactstrap';

const Recaptcha = require('react-recaptcha');

const ContactForm = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [notification, setNotification] = useState({ error: false, msg: '' });
  const recaptchaInstance = useRef(null);
  const form = useRef(null);
  const [resolved, setResolved] = useState(false);

  const formSubmit = (name2, email2, message2) => {
    console.log(name2, email2, message2);
    fetch('https://formcarry.com/s/sFfISVXLJBN', {
      method: 'POST',
      headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
      body: JSON.stringify({
        name,
        email,
        message,
      }),
    }).then((response) => response.json())
      .then((response) => {
      // access response.data in order to check formcarry response

        if (response.code === 200) {
          setNotification(
            {
              error: false,
              msg: 'Ihre Nachricht wurde erfolgreich versendet.',
            },
          );
        } else {
          setNotification(
            {
              error: true,
              msg: 'Ihre Nachricht konnte nicht gesendet werden. Bitte versuchen Sie es erneut.',
            },
          );

          console.log(response.data.message);
        }
      })

      .catch((error) => {
        console.log(error);
        setNotification(
          {
            error: true,
            msg: 'Ihre Nachricht konnte nicht gesendet werden. Bitte versuchen Sie es erneut.',
          },
        );
      });
  };

  useEffect(() => {
    if (!resolved) return;

    const asyncRequest = async () => {
      await formSubmit(name, email, message);
    };
    asyncRequest();
  }, [resolved]);

  const executeCaptcha = (e) => {
    e.preventDefault();
    recaptchaInstance.current.execute();
  };

  return (
    <>
      {
        notification.msg !== '' && (
          <Alert color={notification.error ? 'danger' : 'success'}>
            {notification.msg}
          </Alert>
        )
      }
      <Form ref={form} onSubmit={formSubmit}>
        <Row>
          <Col xs="12">
            <FormGroup>
              <Label for="name">Ihr Name</Label>
              <Input
                type="text"
                name="name"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label for="exampleEmail">Ihre E-Mail-Adresse</Label>
              <Input
                type="email"
                name="email"
                id="exampleEmail"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </FormGroup>
          </Col>
          <Col xs="12">
            <FormGroup>
              <Label for="nachricht">Wie können wir Ihnen weiterhelfen?</Label>
              <Input
                type="textarea"
                name="nachricht"
                id="nachricht"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
            </FormGroup>
            <Recaptcha
              sitekey="6LdkqwwaAAAAAIe21szg1kcyh7KRqlOrcHKJg-1b"
              ref={recaptchaInstance}
              verifyCallback={() => { setResolved(true); }}
              size="invisible"
              onloadCallback={() => { }}
            />
            <Button className="pull-right" onClick={executeCaptcha}>Abschicken</Button>
          </Col>

        </Row>
      </Form>
    </>
  );
};

export default ContactForm;

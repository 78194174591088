import React, { useState } from 'react';
import {
  Container, Col, Row,
} from 'reactstrap';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Headline from '../components/Headline/Headline';
import ContactForm from '../components/ContactForm/ContactForm';
import Section from '../components/Section/Section';

const Kontakt = () => (
  <Layout headerWhite>
    <SEO title="Aktuelles | Orthopädie Dr. Schubert" description="Die Orthopädie Praxis Dr. B. Schubert bietet offene Sprechstunden an ✓ Termine online vereinbaren ✓ Kontaktieren Sie uns gerne ✓ Mail ✓ Telefon ✓" />
    <Section dark>
      <Headline light subtitle="Praxis für Orthopädie">
        Kommen Sie mit uns in Kontakt
      </Headline>
      <Container>
        <Row>
          <Col xs="12" md={{ size: 8, offset: 2 }}>

            <ContactForm />

          </Col>

        </Row>
      </Container>
    </Section>

  </Layout>
);

export default Kontakt;
